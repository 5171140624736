/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { Field } from 'formik'
import { FileUpload } from 'pages/Workspace/layouts/PlanningBoard/components/FileUpload'
import { Quill } from 'pages/Workspace/layouts/PlanningBoard/components/Quill'
import { TripNoteItem } from 'pages/Workspace/layouts/PlanningBoard/types'
import { useAppSelector } from 'store/hooks'

import { ItemPopupFormikValues } from '../../ItemPopup'
import {
  Container,
  AttachmentContainer,
  AttachmentIconStyled,
} from './noteItemPopupDetails.style'

type Props = {
  setFieldValue: (key: string, val: string) => void
  handleChange: (val: string) => void
  setMyFiles: (files: Array<File>) => void
  values: ItemPopupFormikValues
  tripItem: TripNoteItem
  docURL: string | null
  myFiles: Array<File>
  canEdit: boolean
  loading?: boolean
}

const NoteItemPopup = ({
  tripItem,
  docURL,
  myFiles,
  setMyFiles,
  setFieldValue,
  handleChange,
  values,
  canEdit,
  loading
}: Props) => {
  const { user } = useAppSelector(state => state.user)

  return (
    <Container>
      <Field
        data-testid="noteDescription"
        component={Quill}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        value={values.description}
        formikKey="description"
        canEdit={canEdit}
      />
      <AttachmentContainer>
        <AttachmentIconStyled fontSize="large" />
        <Field
          formikKey="files"
          component={FileUpload}
          docURL={docURL}
          docName={tripItem.doc}
          myFiles={myFiles}
          setMyFiles={setMyFiles}
          setFieldValue={setFieldValue}
          canEdit={canEdit}
        />
      </AttachmentContainer>
    </Container>
  )
}

export default NoteItemPopup

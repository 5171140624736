/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { Field } from 'formik'
import { MembersListRow } from 'pages/Workspace/layouts/MembersListRow'
import { FileUpload } from 'pages/Workspace/layouts/PlanningBoard/components/FileUpload'
import {
  TripLocationItem,
  TripMember,
} from 'pages/Workspace/layouts/PlanningBoard/types'
import { useAppSelector } from 'store/hooks'
import { isScreenSize } from 'utils/screenSzie'

import { UserIcon, CalendarIcon, NoteIcon } from 'components/MTIcons'

import { ItemPopupFormikValues } from '../../../../ItemPopup'
import { DateAndTime } from './components/DateAndTime'
import { Note } from './components/Note'
import {
  TripBodyRow,
  TripBodyRowCustom,
  TripBodyRowIcon,
  TripBodyRowContainer,
  AttachmentIconStyled,
} from './locationItemPopupDetails.style'

type Props = {
  setFieldValue: (key: any, val: any) => void
  handleChange: (val: string) => void
  setMyFiles: (files: Array<File>) => void
  values: ItemPopupFormikValues
  tripItem: TripLocationItem
  tripMembers: TripMember[]
  docURL: string | null
  myFiles: Array<File>
  canEdit: boolean
}

const LocationItemPopupDetails = ({
  setFieldValue,
  values,
  handleChange,
  tripItem,
  tripMembers,
  docURL,
  myFiles,
  setMyFiles,
  canEdit,
}: Props) => {
  const { user } = useAppSelector(state => state.user)

  const smallScreen = isScreenSize(830)
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

  const eliminateMargin = smallScreen ? { marginLeft: '0px' } : {}

  return (
    <div>
      {!user?.is_tentative && (
        <TripBodyRow>
          <TripBodyRowIcon style={{ paddingTop: '1px' }}>
            <UserIcon />
          </TripBodyRowIcon>

          <TripBodyRowContainer style={eliminateMargin}>
            <MembersListRow
              tripItem={tripItem}
              tripMembers={tripMembers}
              setFieldValue={setFieldValue}
              canEdit={canEdit}
              showEditIconOnly={true}
              showEditButton={false}
            />
          </TripBodyRowContainer>
        </TripBodyRow>
      )}

      <TripBodyRow>
        <TripBodyRowIcon style={{ paddingTop: isIOS ? '16px' : '5px' }}>
          <CalendarIcon />
        </TripBodyRowIcon>
        <TripBodyRowContainer style={eliminateMargin}>
          <DateAndTime
            smallScreen={smallScreen}
            setFieldValue={setFieldValue}
            values={values}
            canEdit={canEdit}
          />
        </TripBodyRowContainer>
      </TripBodyRow>

      <TripBodyRow>
        <TripBodyRowIcon>
          <NoteIcon />
        </TripBodyRowIcon>
        <TripBodyRowContainer style={eliminateMargin}>
            <Note
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              value={values.note}
              formikKey="note"
              canEdit={canEdit}
            />
        </TripBodyRowContainer>
      </TripBodyRow>

      {!user?.is_tentative && (
        <TripBodyRowCustom>
          <TripBodyRowIcon>
            <AttachmentIconStyled fontSize="large" />
          </TripBodyRowIcon>
          <TripBodyRowContainer style={eliminateMargin}>
            <Field
              formikKey="files"
              component={FileUpload}
              docURL={docURL}
              docName={tripItem.doc}
              myFiles={myFiles}
              setMyFiles={setMyFiles}
              setFieldValue={setFieldValue}
              canEdit={canEdit}
            />
          </TripBodyRowContainer>
        </TripBodyRowCustom>
      )}
    </div>
  )
}

export default LocationItemPopupDetails
